import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import LoginIcon from "@mui/icons-material/Login";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import axios from "axios";
import "./Register.css";


const Login = () => {
  const clientId =
    "457388804534-e3sou8bmis2fgd9dv08emsc31sn0gn37.apps.googleusercontent.com";

  const handleSuccess = async (response) => {
    try {
      const res = await axios.post("/api/user/google-login",
        response,
      );
      if (res.data.success) {
        message.success(res.data.message);
        localStorage.setItem("token", res.data.token);
        navigate("/user-dasboard");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFailure = (error) => {
    message.error("Failed to Login. Try again later.");
  };

  const navigate = useNavigate();
  const [form, setForm] = useState({ email: "", password: "" });
  const [tab, setTab] = useState(0);
  const [otp, setOtp] = useState("1234");
  const [mobile, setMobile] = useState("");
  const [userEnteredMobileOtp, setUserEnteredMobileOtp] = useState("");
  const [mobileOtpTab, setMobileOtpTab] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form?.email === "" || form?.password === "") {
      return message.error(
        `Enter ${form?.email === "" ? "Email" : "Password"}`
      );
    }
    // if (form?.email === process.env.REACT_APP_ADMINMAIL) {
    //   try {
    //     const res = await axios.post("/api/user/admin", form);
    //     if (res.data.success) {
    //       localStorage.setItem("token", res.data.token);
    //       navigate("/admin-dashboard");
    //     } else {
    //       message.error(res.data.message);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // } 
    // else {
    try {
      const res = await axios.post("/api/user/login", form);
      if (res.status === 200 && res.data.success && !res.data.isAdmin) {
        localStorage.setItem("token", res.data.token);
        navigate("/user-dashboard");
      }
      else if (res.status === 200 && res.data.success && res.data.isAdmin) {
        localStorage.setItem("token", res.data.token);
        navigate("/admin-dashboard");

      }


      else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error("Something went wrong");
    }
    // }
  };

  const handleLoginWithOtp = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/user/login-with-otp", {
        mobile: mobile,
      });
      if (res.status === 200 & res.data.success) {
        // const {
        //   otp: encryptedOTP,
        //   key: encryptedKey,
        //   iv: encryptedIv,
        // } = res.data.data;
        // // const encryptKey = process.env.REACT_APP_ENCRYPTKEY.toString('hex')
        // const decryptedKey = decryptData(encryptedKey)
        // const key = CryptoJS.enc.Hex.parse(decryptedKey);
        // const iv = CryptoJS.enc.Hex.parse(encryptedIv);
        // // MOBILE OTP
        // const decryptedOTP = CryptoJS.AES.decrypt(
        //   { ciphertext: CryptoJS.enc.Hex.parse(encryptedOTP) },
        //   key,
        //   { iv: iv }
        // ).toString(CryptoJS.enc.Utf8);
        // // console.log({ key, iv, decryptedOTP })

        // setOtp(decryptedOTP);
        setMobileOtpTab(true);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function handleVerifyMobileOtp(e) {
    e.preventDefault()
    if (!userEnteredMobileOtp) {
      return message.error("Enter OTP");
    }

    try {
      const res = await axios.post("/api/user/login-with-otp-verify", {
        mobile: mobile,
        userEnteredMobileOtp
      });
      if (res.data.success) {
        localStorage.setItem("token", res.data.token);
        navigate("/user-dashboard");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      let { message: msg, remainingAttempts } = error?.response?.data
      const errorMsg = !remainingAttempts ? 'You have exhausted the limit. Please try again after 24 hours.' : `${msg}. ${remainingAttempts} attempt remains`
      message.error(errorMsg);
      console.log(error);

    }
  }

  return (
    <Layout>
      <div className="container-fluid hero-container register-container">
        <div className="row text-center">
          <div className="d-block m-auto col-12 col-sm-12 col-md-6 col-lg-6">
            {/* {tab === 0 && (
              <form className="register-form firstpage d-none">
                <h1>Welcome</h1>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam
                  quia dolore quaerat doloremque molestias, blanditiis magnam
                  eum nemo voluptatum, praesentium ea consequuntur illum,
                  reiciendis officia! Earum ut explicabo distinctio incidunt
                </p>
                <div className="loginbtns">
                  <button className="register-btn" nClick={() => setTab(1)}>
                    Sign In
                  </button>
                  <button
                    className="register-btn"
                    nClick={() => navigate("/register")}
                  >
                    Sign Up
                  </button>
                </div>
              </form>
            )} */}
            {tab === 0 && (
              <form className="register-form">
                <div className="forgot-pass d-flex d-md-none justify-content-center mt-3 mb-4 gap-1 ">
                  <Link to="/login" className="w-50 border border-danger rounded py-2 d-flex align-items-center justify-content-center register-btn text-white"><h6 className="text-center mb-0 text-white"> Login <LoginIcon className="icon" /> </h6></Link>
                  <Link to="/register" className="w-50 d-flex align-items-center justify-content-center border border-danger rounded py-2  ">
                    <h6 className="text-center mb-0">
                      Sign Up <LoginIcon className="icon" />
                    </h6>
                  </Link>
                </div>
                <div className="form-fields mb-3">
                  <input
                    onChange={handleChange}
                    value={form?.email}
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Email"
                  />
                </div>
                <div className="form-fields mb-3">
                  <input
                    onChange={handleChange}
                    value={form?.password}
                    name="password"
                    type="text"
                    className="form-control"
                    placeholder="Password"
                  />
                </div>
                <div className="forgot-pass">
                  <h6 className="text-end my-2">
                    Forgot Password?{" "}
                    <Link to="/forgot-password">Click here</Link>
                  </h6>
                </div>
                <button
                  onClick={handleSubmit}
                  className="register-btn text-white"
                >
                  Login <LoginIcon className="icon" />
                </button>
                <span className="or-span">OR</span>
                <button onClick={() => setTab(1)} className="register-btn">
                  Login with Mobile OTP <MobileFriendlyIcon className="icon" />
                </button>
                <span className="or-span">OR</span>
                <div className="google-btn-container">
                  <GoogleOAuthProvider clientId={clientId}>
                    <GoogleLogin
                      onSuccess={handleSuccess}
                      onError={handleFailure}
                    />
                  </GoogleOAuthProvider>
                  {/* <GoogleLogin
                    clientId={clientId}
                    buttonText="Login with Google"
                    onSuccess={handleSuccess}
                    onFailure={handleFailure}
                    cookiePolicy={"single_host_origin"}
                  /> */}
                </div>
                <div className="forgot-pass d-md-flex justify-content-between d-sm-none">
                  <h6 className="text-center">
                    New Customer? <Link to="/register">Sign Up</Link>
                  </h6>
                </div>
              </form>
            )}
            {tab === 1 && (
              <form className="register-form" onSubmit={handleVerifyMobileOtp}>
                <h1>Login with Mobile OTP</h1>
                <div className="form-fields mb-3">
                  <input
                    onChange={(e) => setMobile(e.target.value)}
                    value={mobile}
                    name="mobile"
                    type="number"
                    className="form-control"
                    placeholder="Enter Registered Mobile Number"
                    readOnly={mobileOtpTab}
                  />
                </div>
                {mobileOtpTab && (
                  <div className="form-fields mb-3">
                    <input
                      onChange={(e) => setUserEnteredMobileOtp(e.target.value)}
                      value={userEnteredMobileOtp}
                      name="mobileotp"
                      type="number"
                      min='4'
                      required
                      className="form-control"
                      placeholder="Enter OTP"
                    />
                  </div>
                )}
                {!mobileOtpTab && (
                  <button
                    onClick={handleLoginWithOtp}
                    className="register-btn text-white"
                  >
                    Login with OTP
                  </button>
                )}
                {mobileOtpTab && (
                  <button
                    onClick={handleVerifyMobileOtp}
                    className="register-btn text-white"
                    type="submit"
                  >
                    Verify and Login
                  </button>
                )}
                <div className="forgot-pass">
                  <h6 className="text-center my-2">
                    login Password?{" "}
                    <Link onClick={() => setTab(0)}>Click here</Link>
                  </h6>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
